<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="Shop-container">
        &nbsp;&nbsp;&nbsp;<router-link to="/adward" class="upbtn" style="background-color:darkgreen">Wards/Streets</router-link>&nbsp;
        &nbsp;&nbsp;&nbsp;<router-link to="/ascat" class="upbtn" style="background-color:darkgreen">Categories</router-link>&nbsp;
       <br><br>
       <div class="Shop-form-container">
           <form id="addShopsForm"  novalidate autocomplete="off">
                <div class="heading">
                    <b><h3>Add Categories</h3></b>
                </div>
               
               <div class="row">
               <div class="col-sm-4">
                   <label for="Splace">Main Category
                   </label>
                       <div><select v-model="CatObj.mainc" id="Splace" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in maincate" :key="m.cat_id" id="Hid"  
                            v-bind:value =m.cat_id>
                               <div>{{m.category_name }}</div> 
                            </option>
                        </select>
                     </div> 

                   <p class="error-mess" v-if="errObj.maincErr.length > 0">{{ errObj.maincErr[0] }}</p>
               </div>
               <div class="col-sm-4">
                   <label for="Snum">Sub Category:
                   </label>
                   <input type="text" name="Snum" id="Snum" class="form-control" 
                     v-model="CatObj.subc" />
                    <p class="error-mess" v-if="errObj.subcErr.length > 0">{{ errObj.subcErr[0] }}</p>
               </div>
               <div class="Addbtn col-sm-4">
                   <label for="s">
                   </label>
                   <input type="button" value="Add" id="Addpay" class=" btn" @click="addToTable"  /> 
               </div>
           </div>
           <br>
           <div style="width: 100%; text-align: center;">
                <h3>Added Sub Categories</h3>
            </div>
           <div class="row">
            <table style="width: 90%; text-align: left; font-size: small; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse; margin-left: auto; margin-right: auto;">
               <thead>
                   <tr>   
                        <th>Index</th>
                       <th>Main Category</th>
                       <th>Sub Category</th>
                       <th>Remove</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" (s,index) in paginatedData" :key="index">
                    <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                       <td style="text-align: left;">{{ s.main_cat_name  }}</td>
                       <td style="text-align: left;">{{ s.sub_cat_name  }}</td>
                       <td><router-link to="" @click="openPopup(b.emp_nic)"  class="fas fa-edit"> </router-link></td>
                     <!-- shop_place 
                     <td><input type="button" @click="deleteShop(s.shop_place_id,s.shop_number)" value="X" class=" btn"></td>-->
                   </tr>
               </tbody>
           </table>
           </div>
           <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
           </form>
       </div>
</div>
<!-- end -->
</template>

<script>
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert'
import { mapMutations } from "vuex";
import { mapState } from "vuex";

export default {

    name: "AssessCategories",

    data() {
       return {
           nic:"",
           sabha: "",
           userLevel:"",
           CatObj : { mainc:"",subc:"" },
           errObj:{maincErr:[], subcErr:[]},
           maincate:[],
           sabhaIncomeHead : undefined,
           subCatall:[],
           itemsPerPage: 10,
            currentPage: 1,
       };
   },
   created() {
       this.getDataFromSessionStorage()
   },
   computed:{
       ...mapState(["user"]),

    paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        
        // Check if this.data is an array before using slice method
        if (Array.isArray(this.subCatall)) {
        return this.subCatall.slice(startIndex, endIndex);
        } else {
        // Return empty array or handle loading state
        return [];
        }
  },
  totalPages() {
      return Math.ceil(this.subCatall.length / this.itemsPerPage);
    },
    //get category if and name 
    mainCategoryName() {
      const selectedCategory = this.maincate.find(m => m.cat_id === this.CatObj.mainc);
      return selectedCategory ? selectedCategory.category_name : '';
    },
   },

   methods: {
       ...mapMutations(["setUser"]),

       async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
                       }
           this.maincate = (await axios.get('/maincat/')).data
           this.subCatall = (await axios.get('/scatbysabha/' +this.sabha)).data
       },
      
nextPage(event) {
  if (this.currentPage < this.totalPages) {
    this.currentPage++;
  }
  event.preventDefault();
},
prevPage(event) {
  if (this.currentPage > 1) {
    this.currentPage--;
  }
  event.preventDefault();
},

async deleteShop(id,place,sid){
    let result = confirm(" Are you Sure you want to Delete?")
        if(result == true){
           await axios.delete("/deletshop/" +  id );
           await axios.delete("/deleteboth/"+place+"/"+sid +"/"+this.sabha );
           this.$refs.alert.showAlert('success', 'Shop deleted Successfully !')
           this.allShops = (await axios.get('/shopsosb/' +this.sabha)).data
        }
       },
       resetCheckErr: function () {
           this.errObj.maincErr = [];
           this.errObj.subcErr =[];
       },
       checkEmptyErr: function () {
           for (var typeErr in this.errObj) {
               if (this.errObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm: function () {
           this.resetCheckErr();
           
           // main categories
           if (!this.CatObj.mainc) {
               this.errObj.maincErr.push("required");
           }
           if (!this.CatObj.subc) {
               this.errObj.subcErr.push("required");
           }
       },
       async addToTable(e) {
            this.checkForm();
            if (!this.checkEmptyErr()) {
                e.preventDefault();
            } else {
                e.preventDefault();
                    let data = {
                        sb_code  : this.sabha,
                        main_cat_id: this.CatObj.mainc,
                        main_cat_name:this.mainCategoryName,
                        sub_cat_name:this.CatObj.subc   
                    }
                    await axios.post("/savesubc/", data)
                    this.$refs.alert.showAlert('success', 'Added Successfully !')
                    this.subCatall = (await axios.get('/scatbysabha/' +this.sabha)).data
                    document.getElementById("addMarketsForm").reset();
            }
        },
   },
   components: {
        VueBasicAlert
    }
};

</script>

<style scoped>
.upbtn {
    padding: 10px 20px;
    background-color: #054818;
    color: white;
    border: none;
    cursor: pointer;
    text-align: center; 
    width: 15rem;
    height: 2.7rem;
    font-size: small;
    padding-top: 0.3rem;
    margin: 1rem 0;
    border-radius: 5px;
}
input[type="button"] {
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
}
/* shroff form container */
.Shop-container {
   background-color: #ffffff09;
   /* height: 50vh; */
   padding: 1rem 2%;
   font-size: 16px;
}

/* ongoing */
.Shop-container .Shop-form-container {
   background: #fff;
   /* width: 100% !important; */
   
}

.Shop-container .Shop-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 2rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
   
}

.Shop-container .Shop-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.Shop-container .Shop-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
} 

.Shop-container .Shop-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.Shop-container .Shop-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
}

.Shop-container .Shop-form-container form .btn {
   /* margin: 1rem 0; */
   width: 25%;
   text-align: center;
   font-size: small;
   background-color : #032a2c;
    height: 3rem;
    color: #fffbfb;
  
   
}
/* .Shop-container .Shop-form-container form .table {
   margin: 1rem 0;
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #e9e4e9;
    height: 3rem;
   
} */
.Shop-container .Shop-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
}

.Shop-container .Shop-form-container form p {
   padding-top: 1rem;
   font-size: 1.5rem;
   color: rgb(243, 47, 47);
   margin: 0;
}

.Shop-container .Shop-form-container form p a {
   color: #27ae60;
}

.Shop-container .Shop-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.Shop-container .Shop-form-container form .form-group {
   margin: 0;
}

.Shop-container .Shop-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 2rem 1.2rem;
}
/* .project-list>tbody>tr>td {
   padding: 12px 8px;
}

.project-list>tbody>tr>td .avatar {
   width: 22px;
   border: 1px solid #CCC;
} */
.add-container {
    padding: 2rem 9%;
    
}

.add-container .add-form-container {
    background: #fff;

}

.add-container .add-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 70rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.add-container .add-form-container form h3 {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.add-container .add-form-container form .form-control {
     margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
     font-size: 1.6rem; 
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}  

.add-container .add-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.add-container .add-form-container form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
     color: #4e0707;
}

.add-container .add-form-container form .btn {
    margin: 1rem 0;
    width: 25%;
    text-align: center;
    /* background-color: #af74a7 ; */
    align-content: center;
    background-color : #032a2c;
  
    color: #e9e4e9;
}

.add-container .add-form-container form p {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: #666;
    margin: 0;
}

.add-container .add-form-container form p a {
    color: #27ae60;
}

.add-container .add-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.add-container .add-form-container form .form-group {
    margin: 0;
}

.add-container .add-form-container form .form-group .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}

</style>