<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="register-container">
        &nbsp;&nbsp;&nbsp;<router-link to="/adward" class="upbtn" style="background-color:darkgreen">Wards/Streets</router-link>&nbsp;
    &nbsp;&nbsp;&nbsp;<router-link to="/ascat" class="upbtn" style="background-color:darkgreen">Categories</router-link>&nbsp;
        <br><br>
        <div class="register-form-container">
            <form id="addBankAccForm" @submit="handleSubmit" novalidate autocomplete="off">
                <div class="heading">
                    <h3><b>Add Wards</b></h3>
                </div>
                <!-- Display checkboxes in two columns -->
                <div class="form-group checkboxes-container">
                    <div v-for="(checkbox, index) in checkboxes" :key="index" class="checkbox-item">
                        <input
                            type="checkbox"
                            :id="'checkbox-' + index"
                            :value="checkbox.value"
                            v-model="selectedValues"
                        />
                        <label :for="'checkbox-' + index">{{ checkbox.label }}</label>
                    </div>
                </div>

                <div class="form-group">
                    <input type="button" value="Save" class="btn" @click="saveWard()" />
                </div>
                <div class="heading">
                    <h3><b>Existing Wards</b></h3>
                </div>
                <div class="wrow">
                    <div v-for="w in savedWards" :key="w.ward_id" class="wward">
                        {{ w.ward }}
                    </div>
                </div>
            </form>
            <!-- second form -->
            <form id="addBankAccForm" @submit="handleSubmit" novalidate autocomplete="off">
                <div class="register-container">
                    <div class="register-form-container">
                    <div class="heading">
                        <h3><b>Add Streets</b></h3>
                    </div>
                    <div class="row">
                    <div class="col-sm-4">
                        <label for="strtnme">Street Name:
                        </label>
                        <input type="text" name="Snum" id="Snum" class="form-control" 
                            v-model="strtObj.stname" />
                            <!-- <p class="error-mess" v-if="errObj.strtErr.length > 0">{{ errObj.strtErr[0] }}</p> -->
               </div>
                <div class="Addbtn col-sm-4">
                    <label for="s">
                    </label><div></div>
                    <input type="button" value="+" id="Addpay" class=" btn" @click="saveStreet()" /> 
                </div>
            </div>
            <div class="heading">
                    <h3><b>Existing Streets</b></h3>
            </div>
            <div class="table-container">
                <table class="ttable">
                    <thead>
                        <tr>
                            <th style="width: 10%; text-align: center;" >No</th>
                            <th style="width: 75%;">Name</th>
                            <th style="width: 15%; text-align: center;">Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(s,index) in paginatedData " :key="s.street_id">
                            <td style="text-align: center;">{{ (currentPage - 1) * 10 + index + 1 }}</td>
                            <td>{{ s.street_name }}</td>
                            <td style="text-align: center;"><router-link to="" @click="openPopup(s.street_id)"  class="fas fa-edit" style="color: darkgreen;"> </router-link></td>
                        </tr>
                    </tbody>
                </table>
                            <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
                            <span>{{ currentPage }} / {{ totalPages }}</span>
                            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
            
                    </div>
                    </div>
                </div>
            </form>
             <!-- end -->
              <!-- popup edit -->
     <div class="modal" v-if="showPopup">
      <div class="modal-content" ref="modalRef">
        <button class="close-x" @click="closePopup"> X</button>
        <div class="modal-header">
          <h1>Edit Street</h1>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="model-group">
                   <label for="sTaxNic"><b>Street Name:</b>
                   </label>
                   <input type="text" name="sTaxNic"  id="sTaxNic" class="form-control" 
                   v-model="popObj.Streetname"/>
               </div>
            </div>
        </div> 
        <div class="modal-footer">

          <button class="close-button" @click="closePopup">Close</button>
          <button class="save-button" @click="UpdateStreet">Update</button>
        </div>
              <!-- popup end -->
      </div>
     </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert'
//import { ref } from 'vue'
export default {

    name: "WardsAdd",

    data() {
        return {
            checkboxes: [
            { value: 'Ward 1', label: 'Ward 1' },
            { value: 'Ward 2', label: 'Ward 2' },
            { value: 'Ward 3', label: 'Ward 3' },
            { value: 'Ward 4', label: 'Ward 4' },
            { value: 'Ward 5', label: 'Ward 5' },
            { value: 'Ward 6', label: 'Ward 6' },
            { value: 'Ward 7', label: 'Ward 7' },
            { value: 'Ward 8', label: 'Ward 8' },
            { value: 'Ward 9', label: 'Ward 9' },
            { value: 'Ward 10', label: 'Ward 10' },
            { value: 'Ward 11', label: 'Ward 11' },
            { value: 'Ward 12', label: 'Ward 12' },
            { value: 'Ward 13', label: 'Ward 13' },
            { value: 'Ward 14', label: 'Ward 14' },
            { value: 'Ward 15', label: 'Ward 15' },
            { value: 'Ward 16', label: 'Ward 16' },
            { value: 'Ward 17', label: 'Ward 17' },
            { value: 'Ward 18', label: 'Ward 18' },
            { value: 'Ward 19', label: 'Ward 19' },
            { value: 'Ward 20', label: 'Ward 20' }
      ],
      // Array to track selected checkbox values
            selectedValues: [],
            nic:"",
            sabha: "",
            userLevel:"",
            savedWards:[],
            strtObj:{stname:""},
            errObj:{strtErr:[]},
            savedStreets:[],
            itemsPerPage: 10,
            currentPage: 1,
            popObj:{Streetname:""},
            popErr:{popstreetErr:[]},
            showPopup:false,
            streetselected:[],
            selectedStr:"",
        }
    },
    created() {
        this.getDataFromSessionStorage()
    },
    computed:{
    paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        
        // Check if this.data is an array before using slice method
        if (Array.isArray(this.savedStreets)) {
        return this.savedStreets.slice(startIndex, endIndex);
        } else {
        // Return empty array or handle loading state
        return [];
        }
  },
  totalPages() {
      return Math.ceil(this.savedStreets.length / this.itemsPerPage);
    },
    },
   
    methods: {
        async getDataFromSessionStorage(){
            const data = JSON.parse(sessionStorage.getItem('userData'))
            if(data){
                            this.nic = data.nic 
                            this.sabha = data.sabha
                            this.userLevel =data.userLevel
                        }
            this.savedWards= (await axios.get('/wdbys/'+this.sabha)).data;
            this.savedStreets = (await axios.get('/stbys/'+this.sabha)).data;
        },
nextPage(event) {
  if (this.currentPage < this.totalPages) {
    this.currentPage++;
  }
  // Prevent default form submission behavior
  event.preventDefault();
},
prevPage(event) {
  if (this.currentPage > 1) {
    this.currentPage--;
  }
  // Prevent default form submission behavior
  event.preventDefault();
},
        async saveWard() {
    try {
        if (this.selectedValues.length > 0) {
            for (let i = 0; i < this.selectedValues.length; i++) {
                let data = {
                    sb_code: this.sabha,
                    ward: this.selectedValues[i]
                };

                // Send the POST request for each selected ward
                await axios.post("/saveward/", data);
            }
            
            // Show success alert after all requests
            this.$refs.alert.showAlert('success', 'Wards Added Successfully!');

            // Clear the form or reset checkboxes after all wards are saved
            this.selectedValues = [];
            this.savedWards= (await axios.get('/wdbys/'+this.sabha)).data;
        } else {
            // Handle case where no wards are selected
            this.$refs.alert.showAlert('warning', 'No Wards Selected');
        }
    } catch (error) {
        // Check if the error is a duplicate entry (MySQL error code: 1062)
        if (error.response && error.response.data && error.response.data.errno === 1062) {
            this.$refs.alert.showAlert('warning', 'Duplicate entry. This ward already exists.');
        } else {
            console.error('Error saving wards:', error);
            // Show error alert in case of failure
            this.$refs.alert.showAlert('error', 'Error saving wards. Please try again.');
        }
    }

},
resetCheckErr: function () {
            this.errObj.strtErr = [];
          
        },
        checkEmptyErr: function () {
            for (var typeErr in this.errObj) {
                if (this.errObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },
        checkForm: function () {
            this.resetCheckErr();

            // Head Id/code validate
            if (!this.strtObj.stname) {
                this.errObj.strtErr.push("Street is required");
            }             
        },
async saveStreet(e){
    this.checkForm();
            if (!this.checkEmptyErr()) {
                e.preventDefault();
            } else {
                // e.preventDefault();
                let streetdata={
                    sb_code:this.sabha,
                    street_name: this.strtObj.stname
                }
                await axios.post("/savestr/", streetdata);
                this.$refs.alert.showAlert('success', 'Street Added Successfully !')
                this.savedStreets = (await axios.get('/stbys/'+this.sabha)).data;
            }
},
//popup
async openPopup(id) {
            this.showPopup = true;
            document.addEventListener('mouseup', this.closeModalOnClickOutside);
            this.streetselected= (await axios.get('/stbsid/'+id)).data;
            if( this.streetselected){
            this.popObj.Streetname=this.streetselected[0].street_name;
            this.selectedStr=id;
            }
        },
        closePopup() {
            this.showPopup = false;
            document.removeEventListener('mouseup', this.closeModalOnClickOutside);
        },
        closeModalOnClickOutside(event) {
            const modal = this.$refs.modalRef;
            if (!modal.contains(event.target)) {
                this.closePopup();
            }
        },
        // popup empty check
        resetCheckArrPopErr: function () { 
            this.popObj.Streetname = [];
          
        },
        checkArePopup: function () {
            this.resetCheckArrPopErr();
            if (!this.popObj.Streetname) {
                this.popErr.popstreetErr.push("Name is required");
            }
        },
        UpdateStreet(){

        }
       },

    components: {
        VueBasicAlert
    }
}
</script>

<style scoped>
.upbtn {
    padding: 10px 20px;
    background-color: #054818;
    color: white;
    border: none;
    cursor: pointer;
    text-align: center; 
    width: 15rem;
    height: 2.7rem;
    font-size: small;
    padding-top: 0.3rem;
    margin: 1rem 0;
    border-radius: 5px;
}
.tbtn {
    width: 90%;
    padding: 10px;
    background-color: #054818;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    text-align: center;
}
.table-container {
  width: 80%;
  margin: 0 auto; /* Center the table */
}
.ttable {
  width: 100%;
  border-collapse: collapse;
}

.ttable th {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
  font-size: 1.5rem;
}
.ttable td {
  padding: 4px;
  padding-top: 2px;
  padding-bottom: 1px;
  text-align: left;
  border: 1px solid #ddd;
  font-size: 1.3rem;
}

.ttable th {
  background-color: #f2f2f2;
}

.wrow {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
}

.wward {
    flex: 0 0 10%;
    text-align: left;
    font-size: 1.5rem;
}
.empty-space {
  flex: 0 0 10%; /* Fixed width of 10% for empty spaces */
}
/* Flexbox layout for 2 columns and 10 rows */
.checkboxes-container {
    display: grid;
    grid-template-columns: repeat(10, 1fr); /* Two columns */
    grid-gap: 10px;
}

.checkbox-item {
    display: flex;
    align-items: center;
}

/* Optional styling for the form and general layout */
/* .register-container {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.register-form-container {
    width: 100%;
    /* max-width: 400px; */
    
}

.form-group {
    margin-bottom: 1rem;
}

.heading h3 {
    text-align: center;
}

.btn {
    width: 20%;
    padding: 5px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
}
/* new */
.register-container {
    padding: 1rem 2%;
    /* min-height: 72.3vh; */
}

.register-container .register-form-container {
    background: #fff;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 70rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
    margin-bottom: 1.0rem
}

.register-container .register-form-container form h3 {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.register-container .register-form-container form .form-control {
     margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
     font-size: 1.6rem; 
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
} 

.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
     color: #022e2a;
}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 20%;
    text-align: center;
    background-color:#022e2a ;
    padding: 2px;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    justify-content: flex-end;
}

.register-container .register-form-container form p {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group {
    margin: 0;
}

.register-container .register-form-container form .form-group .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
/* pop up */
.modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;
      border-radius: 0.5rem;
  }

  .modal-content {
      background-color: white;
      padding: 5px;
      border-radius: 1px;
      /* max-width: 500px; */
      width: 30%;
      position: relative;
      font-size: 1.3rem;
  }

  .close x {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 18px;
      color: #555;
  }

  .close-x:hover {
      color: #ff0000;
      /* Change to your desired hover color */
  }


  .modal-header {
      padding-bottom: 2px;
      border-bottom: 1px solid #ccc;
      text-align: center;
  }

  .modal-body {
      /* padding: 10px 20px 20px 20px; */
      padding-left: 5%; 

  }
  .model-group {
      padding: 10px ;
      width:90%;
      /* padding-left: 10%;
      padding-right: 10%;
      padding-bottom: 10%; */
      /* margin: 10px; */
      /* justify-content: left; */
      /* margin-right: 10px; */
  }
  .modal-body.model-group .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 10%;
    padding: 0;
    width: 90%;

} 
.error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
.modal-body.row {
    display: flex;
    margin: 10%;
  }
  .modal-footer {
      padding-top: 5px;
      border-top: 1px solid #ccc;
      display: flex;
      justify-content: flex-end;
  }

  .close-button,
  .save-button {
      padding: 5px 10px;
      border: none;
      cursor: pointer;
      font-weight: bold;
      border-radius: 4px;
      transition: background-color 0.3s ease;
  }

  .close-button {
      background-color: #f44336;
      color: white;
  }

  .save-button {
      background-color: #4CAF50;
      color: white;
      margin-left: 5px;
  }

  .close-button:hover {
      background-color: #d32f2f;
  }

  .save-button:hover {
      background-color: #45a049;
  }
  .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}
</style>